html,
body {
  height: 100%;
  overflow: hidden;
}
body,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
p,
blockquote,
dl,
dt,
dd,
ul,
ol,
li,
pre,
form,
fieldset,
legend,
button,
input,
textarea,
th,
td,
select {
  margin: 0;
  padding: 0;
}
body,
button,
input,
select,
textarea {
  font-size: 0.3rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
}
address,
cite,
dfn,
em,
var {
  font-style: normal;
}
code,
kbd,
pre,
samp {
  font-family: couriernew, courier, monospace;
}
small {
  font-size: 12px;
}
ul,
ol {
  list-style: none;
}
sup {
  vertical-align: text-top;
}
sub {
  vertical-align: text-bottom;
}
legend {
  color: #000;
}
fieldset,
img {
  border: 0;
}
button,
input,
select,
textarea {
  font-size: 100%;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
menu,
nav,
section {
  display: block;
}
input,
input[type="search"],
button,
select,
option,
textarea,
a {
  outline: none;
  border: 0;
  -webkit-appearance: none;
  border-radius: 0;
  background: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
/* custom */
a {
  text-decoration: none;
  -webkit-backface-visibility: hidden;
  color: #333;
}
body,
input,
textarea {
  -webkit-text-size-adjust: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-family: "PingFang SC", "STHeitiSC-Light", "Helvetica-Light", arial,
    sans-serif, "Droid Sans Fallback";
  color: #333;
}
div,
section,
header,
ul,
li,
footer {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
input {
  line-height: normal;
  box-sizing: border-box;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
.clear {
  clear: both;
  height: 0;
  line-height: 0;
  font-size: 0;
}
.clearfix:after {
  content: ".";
  display: block;
  height: 0;
  visibility: hidden;
  clear: both;
  overflow: hidden;
}
::-webkit-scrollbar {
  width: 0px;
}
::-webkit-scrollbar-track {
  background-color: unset;
}

::-webkit-scrollbar-thumb {
  background-color: unset;
}

::-webkit-scrollbar-thumb:hover {
  background-color: unset;
}

::-webkit-scrollbar-thumb:active {
  background-color: unset;
}
.flex {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.con-cell {
  display: table-cell;
  height: 100%;
  vertical-align: middle;
}
.old-price {
  text-decoration: line-through;
}

.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
/* @font-face {
  font-family: "GuildfordProBook 5";
  src: url("GuildfordProBook 5.otf");
} */
[v-cloak] {
  display: none;
}
.iconfont {
  font-size: 0.36rem;
}
/* 一像素边框 */
@media (-webkit-min-device-pixel-ratio: 1.5), (min-device-pixel-ratio: 1.5) {
  .border-1px::after {
    -webkit-transform: scaleY(0.7);
    -moz-transform: scaleY(0.7);
    -o-transform: scaleY(0.7);
    -ms-transform: scaleY(0.7);
    transform: scaleY(0.7);
  }
  .border-1px::before {
    -webkit-transform: scaleY(0.7);
    -moz-transform: scaleY(0.7);
    -o-transform: scaleY(0.7);
    -ms-transform: scaleY(0.7);
    transform: scaleY(0.7);
  }
}
@media (-webkit-min-device-pixel-ratio: 2), (min-device-pixel-ratio: 2) {
  .border-1px::after {
    -webkit-transform: scaleY(0.5);
    -moz-transform: scaleY(0.5);
    -o-transform: scaleY(0.5);
    -ms-transform: scaleY(0.5);
    transform: scaleY(0.5);
  }
  .border-1px::before {
    -webkit-transform: scaleY(0.5);
    -moz-transform: scaleY(0.5);
    -o-transform: scaleY(0.5);
    -ms-transform: scaleY(0.5);
    transform: scaleY(0.5);
  }
}
@media (-webkit-min-device-pixel-ratio: 3), (min-device-pixel-ratio: 3) {
  .border-1px::after {
    -webkit-transform: scaleY(0.33);
    -moz-transform: scaleY(0.33);
    -o-transform: scaleY(0.33);
    -ms-transform: scaleY(0.33);
    transform: scaleY(0.33);
  }
  .border-1px::before {
    -webkit-transform: scaleY(0.33);
    -moz-transform: scaleY(0.33);
    -o-transform: scaleY(0.33);
    -ms-transform: scaleY(0.33);
    transform: scaleY(0.33);
  }
}
.line1 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.line2 {
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 555;
  background-color: rgba(0, 0, 0, 0.5);
}
